import React, { FunctionComponent, useState, ReactElement } from 'react';
import uuid from 'uuid';
import { MultiStepFormStyle } from './MultiStepFormStyle';
import { StepLabel, Step } from './Step';
import { navigate } from '@reach/router';
import { css } from '@emotion/core';

import DetailStep, { YourSelf } from '../../routes/RegisterPage/DetailStep';
import ExperienceStep, { WorkingExperience } from '../../routes/RegisterPage/ExperienceStep';
import EquipmentStep, { Equipment } from '../../routes/RegisterPage/EquipmentStep';
import api from '../../services/api';
import { FadeLoader } from 'react-spinners';
import { useQueryParam } from "gatsby-query-params";
import ConfirmModal from '../ConfirmModal';

class PhotographerInfos {
  portfolioUrl?: string;
  workExperience?: string;
  photoUrls?: [string];
  zipFileUrls?: [string];
  cameraBrand?: string;
  cameraModel?: string;
  cameraInfo?: string;
}
class Photographer {
  firstName?: string;
  lastName?: string;
  email?: string;
  postalCode?: string;
  city?: string;
  phoneNumber?: string;
  birthDay?: string;
  birthMonth?: string;
  birthYear?: string;
  photographerInfos?: PhotographerInfos
}

var photographer = new Photographer();
photographer.photographerInfos = new PhotographerInfos();

export interface StepData {
  stepName?: string;
  description?: string;
  content: ReactElement;
}

interface MultiStepFormProps {
  className?: string;
  onIndexChange?: (index: number) => void;
}

interface MultiStepFormState {
  currentIndex: number;
  isLoading: boolean;
}

interface ConfirmModal {
  className?: string;
  header?: string;
  body?: string;
  buttonText?: string;
  showing?: boolean;
  callback?: () => void;
}

const MultiStepForm: FunctionComponent<MultiStepFormProps> = props => {
  const { className, onIndexChange } = props;
  const firstName = useQueryParam('firstName', null);
  const email = useQueryParam('email', null);

  photographer.firstName = firstName;
  photographer.email = email;

  var yourself = {
    firstName: photographer.firstName,
    lastName: photographer.lastName,
    email: photographer.email,
    postalCode: photographer.postalCode,
    city: photographer.city,
    phoneNumber: photographer.phoneNumber,
    birthDay: photographer.birthDay,
    birthMonth: photographer.birthMonth,
    birthYear: photographer.birthYear
  }

  var experience = {
    portfolioUrl: photographer.photographerInfos?.portfolioUrl,
    workExperience: photographer.photographerInfos?.workExperience
  }

  var equipment = {
    cameraBrand: photographer.photographerInfos?.cameraBrand,
    cameraModel: photographer.photographerInfos?.cameraModel,
    cameraInfo: photographer.photographerInfos?.cameraInfo
  }

  const steps: StepData[] = [
    {
      stepName: 'Details',
      description: 'Tell us about yourself',
      content: <DetailStep
        onYourSelf={(value: YourSelf) => {
          photographer.firstName = value.firstName;
          photographer.lastName = value.lastName;
          photographer.email = value.email;
          photographer.postalCode = value.postalCode;
          photographer.city = value.city;
          photographer.phoneNumber = value.phoneNumber;
          photographer.birthDay = value.birthDay;
          photographer.birthMonth = value.birthMonth;
          photographer.birthYear = value.birthYear;

          yourself.firstName = value.firstName;
          yourself.lastName = value.lastName;
          yourself.email = value.email;
          yourself.postalCode = value.postalCode;
          yourself.city = value.city;
          yourself.phoneNumber = value.phoneNumber;
          yourself.birthDay = value.birthDay;
          yourself.birthMonth = value.birthMonth;
          yourself.birthYear = value.birthYear;
          onNext();
        }}
        initialValues={yourself}
      />,
    },
    {
      stepName: 'Your experience',
      description: 'Show us your work',
      content: <ExperienceStep
        onExperienceNext={(value: WorkingExperience) => {
          photographer.photographerInfos!.portfolioUrl = value.portfolioUrl;
          photographer.photographerInfos!.workExperience = value.workExperience;
          photographer.photographerInfos!.photoUrls = value.photoUrls;
          photographer.photographerInfos!.zipFileUrls = value.zipFileUrls;

          experience.portfolioUrl = value.portfolioUrl;
          experience.workExperience = value.workExperience;
          experience.photoUrls = value.photoUrls;
          experience.zipFileUrls = value.zipFileUrls;

          onNext();
        }}
        initialValues={experience}
        onExperienceBack={(value: WorkingExperience) => {
          photographer.photographerInfos!.portfolioUrl = value.portfolioUrl;
          photographer.photographerInfos!.workExperience = value.workExperience;
          photographer.photographerInfos!.photoUrls = value.photoUrls;
          photographer.photographerInfos!.zipFileUrls = value.zipFileUrls;

          experience.portfolioUrl = value.portfolioUrl;
          experience.workExperience = value.workExperience;
          experience.photoUrls = value.photoUrls;
          experience.zipFileUrls = value.zipFileUrls;

          onBack();
        }} />,
    },
    {
      stepName: 'Your equipment',
      description: 'Tell us about your camera',
      content: <EquipmentStep
        onEquipmentNext={(value: Equipment) => {
          photographer.photographerInfos!.cameraBrand = value.cameraBrand;
          photographer.photographerInfos!.cameraInfo = value.cameraInfo;
          photographer.photographerInfos!.cameraModel = value.cameraModel;

          equipment.cameraBrand = value.cameraBrand;
          equipment.cameraModel = value.cameraModel;
          equipment.cameraInfo = value.cameraInfo;

          setState({ ...state, isLoading: true });
          photographer.birthMonth = `${parseInt(photographer.birthMonth!) + 1}`;
          // Call API
          api({
            method: 'post',
            url: `/auth/register/photographer`,
            data: photographer
          })
            .then(response => {
              setState({ ...state, isLoading: false });
              if ('success' in response.data && response.data.success === true) {
                state.currentIndex = 0;

                yourself = {
                  firstName: '',
                  lastName: '',
                  email: '',
                  postalCode: '',
                  city: '',
                  phoneNumber: '',
                  birthDay: 'Day',
                  birthMonth: 'Month',
                  birthYear: 'Year'
                }

                experience = {
                  portfolioUrl: '',
                  workExperience: ''
                }

                equipment = {
                  cameraBrand: '',
                  cameraModel: '',
                  cameraInfo: '',
                }
                photographer = new Photographer();
                photographer.photographerInfos = new PhotographerInfos();

                setConfirmation({ ...confirmation, showing: true });
              } else {
                setConfirmation({
                  ...confirmation,
                  header: 'Warning',
                  body: response.data.message,
                  buttonText: 'OK',
                  showing: true,
                  callback: () => {
                    setConfirmation({ ...confirmation, showing: false });
                  }
                });
              }
            })
            .catch(error => {
              setState({ ...state, isLoading: false });
              console.log(error);
              setConfirmation({
                ...confirmation,
                header: 'Warning',
                body: 'An error occured when signing up. Please try again.',
                buttonText: 'OK',
                showing: true,
                callback: () => {
                  setConfirmation({ ...confirmation, showing: false });
                }
              });
            });
        }}
        initialValues={equipment}
        onEquipmentBack={(value: Equipment) => {
          photographer.photographerInfos!.cameraBrand = value.cameraBrand;
          photographer.photographerInfos!.cameraInfo = value.cameraInfo;
          photographer.photographerInfos!.cameraModel = value.cameraModel;

          equipment.cameraBrand = value.cameraBrand;
          equipment.cameraModel = value.cameraModel;
          equipment.cameraInfo = value.cameraInfo;

          onBack();
        }} />
    },
  ];

  const goToHomePage = () => {
    navigate('/');
  }

  const [state, setState] = useState<MultiStepFormState>({
    currentIndex: 0,
    isLoading: false
  });

  const [confirmation, setConfirmation] = useState<ConfirmModal>({
    header: 'We’ll be in touch soon!',
    body: 'Thanks for signing up. We\'re really excited to review your application and a member of our team will call or email you soon.',
    buttonText: 'Return to homepage',
    showing: false,
    callback: goToHomePage
  });

  const onBack = () => {
    if (state.currentIndex <= 0) return;
    state.currentIndex--;
    setState({
      ...state,
    });
    if (onIndexChange) onIndexChange(state.currentIndex);
  };

  const onNext = () => {
    if (state.currentIndex === 0) {
      navigate(`/register?firstName=${photographer.firstName}&email=${encodeURIComponent(photographer.email!)}`, { replace: true });
    }

    if (state.currentIndex >= steps.length - 1) return;
    state.currentIndex++;
    setState({
      ...state,
    });
    if (onIndexChange) onIndexChange(state.currentIndex);
  };

  return (
    <MultiStepFormStyle className={className}>
      <div
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          display: ${confirmation.showing ? 'flex' : 'none'};
          justify-content: center;
          align-items: center;
          .spinner {
            transform: translateY(-4em);
          }
        `}>
        <ConfirmModal
          header={confirmation.header}
          body={confirmation.body}
          buttonText={confirmation.buttonText}
          callback={confirmation.callback}
          css={css`
            .header {
              .title {
                padding: 0 50px;
              }
            }

            @media only screen and (max-width: 320px) {
              .description {
                padding: 0 16px;
              }
            }
          `} />
      </div>
      <div
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          display: ${state.isLoading ? 'flex' : 'none'};
          justify-content: center;
          align-items: center;
          .spinner {
            transform: translateY(-4em);
          }
      `}
      >
        <div className="spinner">
          <FadeLoader color="#fff" />
        </div>
      </div>
      <div className="step-header">
        {steps.map((step, index) => {
          return <StepLabel key={uuid()} index={index} label={step.stepName} isActive={state.currentIndex === index} />;
        })}
      </div>
      <div className="step-form">
        {steps.map((step, index) => {
          return (
            <Step key={uuid()} isActive={state.currentIndex === index} description={step.description}>
              {step.content}
            </Step>
          );
        })}
      </div>
    </MultiStepFormStyle>
  );
};

export default MultiStepForm;
