import React, { FunctionComponent, useState } from 'react';
import { css } from '@emotion/core';
import FormField from '../../../components/Form/FormField';
import TextArea from '../../../components/Form/TextArea';
import ImageUpload from '../../../components/ImageUpload';
import Button from '../../../components/Form/Button';
import ImageIcon from '../../../components/ImageIcon';
import { Form } from 'react-final-form';
import { FadeLoader } from 'react-spinners';

import { storage } from '../../../firebase/firebase';


const destinationFolder = 'photographer_experience_images';
const urlRegrex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;

export interface WorkingExperience {
  portfolioUrl?: string;
  workExperience?: string;
  photoUrls?: [string];
  zipFileUrls?: [string];
}

interface Props {
  initialValues?: WorkingExperience;
  onExperienceNext: (data: WorkingExperience) => void;
  onExperienceBack: (data: WorkingExperience) => void;
}

const ExperienceStep: FunctionComponent<Props> = props => {
  const { onExperienceNext, onExperienceBack, initialValues } = props;
  var formValues: any = null;
  var uploadFiles: any = [];
  var photoUrls: string[] = [];
  var zipFileUrls: string[] = [];

  const [state, setState] = useState({
    isLoading: false
  })

  const onSubmitForm = async (values: object) => {
    if (uploadFiles.length > 0) {
      setState({ ...state, isLoading: true });
      try {
        await uploadFilesToFirebaseStorage(uploadFiles);
      } catch (error) {
        console.log(error);
      }
      setState({ ...state, isLoading: false });
    } else {
      setState({ ...state, isLoading: false });
    }
    values.photoUrls = photoUrls;
    values.zipFileUrls = zipFileUrls;
    onExperienceNext(values);
  }

  const onBack = () => {
    onExperienceBack(formValues);
  }

  const onSelectedFiles = (files: [any]) => {
    uploadFiles = files;
  };

  const uploadFilesToFirebaseStorage = async (files: [any]) => {
    for (let uploadFile of files) {
      let uploadPath = uploadFile.name.includes('.zip') ? uploadFile.name.replace(/ /g, '') : `/${destinationFolder}/${uploadFile.name.replace(/ /g, '')}`
      await storage.ref(uploadPath).put(uploadFile);

      if (uploadFile.name.includes('.zip')) {
        let url = await storage.ref().child(uploadFile.name.replace(/ /g, '')).getDownloadURL();
        zipFileUrls.push(url);
      } else {
        let url = await storage.ref(destinationFolder).child(uploadFile.name.replace(/ /g, '')).getDownloadURL();
        photoUrls.push(url);
      }
    }
  }

  return (
    <div>
      <div
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          display: ${state.isLoading ? 'flex' : 'none'};
          justify-content: center;
          align-items: center;
          .spinner {
            transform: translateY(-4em);
          }
        `}
      >
        <div className="spinner">
          <FadeLoader color="#fff" />
        </div>
      </div>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validate={values => {
          const errors = {};
          if (values.portfolioUrl) {
            if (!values.portfolioUrl.includes('http://') || !values.portfolioUrl.includes('https://')) {
              values.portfolioUrl = `http://${values.portfolioUrl}`;
              if (!urlRegrex.test(values.portfolioUrl)) {
                errors.portfolioUrl = 'Please input valid URL';
              }
            }
          }
          if (!values.workExperience) {
            errors.workExperience = 'This field is required';
          }
          return errors;
        }}
        render={({ handleSubmit, values }) => {
          formValues = values;
          return (
            <form
              onSubmit={handleSubmit}
              css={css`
              width: 100% !important;
              display: flex;
              flex-direction: column;
              .note {
                color: #a5a7ad;
                font-size: 13px;
                line-height: 20px;
                margin: 0;
                padding: 0;
                margin-bottom: 1rem;

                &.img-upload {
                  @media screen and (max-width: 540px) {
                    display: none;
                  }
                }
                &.no-img-upload {
                  display: none;
                  @media screen and (max-width: 540px) {
                    display: block;
                  }
                }
              }
              .img-uploads {
                @media screen and (max-width: 540px) {
                  display: none;
                }
              }

              @media screen and (max-width: 540px) {
                .note {
                  display: none;
                }
              }
            `}>
              <p className="note">You don’t have to be a veteran, but we’d like to see your style.</p>

              <FormField
                type="text"
                name="portfolioUrl"
                label="Enter a portfolio URL (if you have one)"
                offsetTopMoved="7px"
              />

              <FormField
                component={TextArea}
                className="input-control text-area-control"
                name="workExperience"
                rows={4}
                label="Tell us a little about your experience as a photographer…"
                maxLength={5000}
                offsetTopMoved="7px"
              />

              <p className="note img-upload">Please upload some photos before and after editing</p>
              <p className="note no-img-upload">We will reach out to ask for additional work examples if required.</p>

              <ImageUpload className="img-uploads" onFileSelected={onSelectedFiles} />
              <div className="step-control">
                <Button
                  className="btn-control back-btn"
                  icon={<ImageIcon className="img-icon" src="/icon/black_left_arrow.svg" />}
                  label="Back"
                  background="transparent"
                  reverse
                  onClick={onBack}
                />
                <Button
                  className="btn-control next-btn"
                  type="submit"
                  icon={<ImageIcon className="img-icon" src="/icon/right_arrow.svg" />}
                  label={'Continue'}
                />
              </div>
            </form>
          )
        }} />
    </div>
  );
};

export default ExperienceStep;
