import styled from '@emotion/styled';

const MultiStepFormStyle = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
  max-width: 40rem;
  .btn-control {
    margin: 0;
    font-size: 15px;
    line-height: 18px;
    font-family: RubikMedium;
    padding: 14px 30px;
    .img-icon {
      width: 15.5px;
      height: 10px;
      margin: 0;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .step-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .step-control {
    display: flex;
    justify-content: space-between;
    margin-top: 1.2rem;
    .back-btn {
      max-width: 8rem;
      padding-left: 0;
    }
    .next-btn {
      max-width: 165px;
    }
  }
`;

export { MultiStepFormStyle };
