import firebase from 'firebase/app';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBAYd175Kx43IaQi4P0mdoz6mDIJu1DbhY",
  authDomain: "travelpix-29c02.firebaseapp.com",
  databaseURL: "https://travelpix-29c02.firebaseio.com",
  projectId: "travelpix-29c02",
  storageBucket: "travelpix-29c02.appspot.com",
  messagingSenderId: "930497610973",
  appId: "1:930497610973:web:d2c42650bbc6518f6e4734",
  measurementId: "G-2YJK42BVVG"
};
firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();

export {
  storage, firebase as default
}

