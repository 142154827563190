import React, { FunctionComponent } from 'react';
import { css } from '@emotion/core';
import FormField from '../../../components/Form/FormField';
import { Form } from 'react-final-form';
import Button from '../../../components/Form/Button';
import ImageIcon from '../../../components/ImageIcon';
import PhoneInputCustom from '../../../components/Form/PhoneInput';
import YearPickerCustom from '../../../components/Form/YearPicker';
import MonthPickerCustom from '../../../components/Form/MonthPicker';
import DayPickerCustom from '../../../components/Form/DayPicker';

const emailRegrex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export interface YourSelf {
  firstName?: string;
  lastName?: string;
  email?: string;
  postalCode?: string;
  city?: string;
  phoneNumber?: string;
  birthDay?: string;
  birthMonth?: string;
  birthYear?: string;
}
interface Props {
  initialValues?: YourSelf;
  onYourSelf: (data: YourSelf) => void
}

const DetailStep: FunctionComponent<Props> = props => {
  const { onYourSelf, initialValues } = props;

  const onSubmitForm = (values: object) => {
    onYourSelf(values);
  }

  return (
    <Form
      onSubmit={onSubmitForm}
      initialValues={initialValues}
      validate={values => {
        const errors = {};
        if (!values.firstName) {
          errors.firstName = 'This field is required';
        }
        if (!values.lastName) {
          errors.lastName = 'This field is required';
        }
        if (!values.email || !emailRegrex.test(values.email)) {
          errors.email = 'Please input a valid email e.g. name@example.com';
        }
        if (!values.postalCode || values.postalCode.length < 2) {
          errors.postalCode = 'Please input a valid postcode e.g. E1 5EH';
        }
        if (!values.city) {
          errors.city = 'This field is required';
        }
        if (!values.phoneNumber || values.phoneNumber.length < 5) {
          errors.phoneNumber = 'Please input a valid phone number';
        }
        if (!values.birthDay) {
          errors.birthDay = 'Required'
        }
        if (!values.birthMonth) {
          errors.birthMonth = 'Required'
        }
        if (!values.birthYear) {
          errors.birthYear = 'Required'
        }
        return errors;
      }}
      render={({ handleSubmit }) => {
        return (
          <form
            onSubmit={handleSubmit}
            css={css`
                width: 100% !important;
                display: flex;
                flex-direction: column;
                .age-note {
                  color: #a5a7ad;
                  margin: 0;
                  padding: 0;
                  font-size: 13px;
                  line-height: 20px;
                }
              `}>

            <FormField
              type="text"
              name="firstName"
              label="First name"
              offsetTopMoved="7px"
            />

            <FormField
              type="text"
              name="lastName"
              label="Last name"
              offsetTopMoved="7px"
            />

            <FormField
              type="email"
              name="email"
              label="Your email (name@example.com)"
              offsetTopMoved="7px"
            />

            <FormField
              type="text"
              name="postalCode"
              label="Your postcode"
              offsetTopMoved="7px"
            />

            <FormField
              type="text"
              name="city"
              label="Your city"
              offsetTopMoved="7px"
            />

            <FormField
              component={PhoneInputCustom}
              name="phoneNumber"
              label="Your phone number"
              offsetTopMoved="7px"
              country="gb"
              enableAreaCodes={true}
            />

            <span
              css={css`
                font-family: RubikMedium;
                font-size: 14px;
                margin-bottom: 8px;
              `}
            >Birthday
            </span>

            <div
              css={css`
                display: flex;
                flex-direction:row;`
              }>
              <FormField
                component={DayPickerCustom}
                name="birthDay"
                label="Day of birth"
                offsetTopMoved="7px"
              />

              <FormField
                css={css`
                  margin-left: 4px;
                `}
                component={MonthPickerCustom}
                name="birthMonth"
                label="Month of birth"
                offsetTopMoved="7px"
              />

              <FormField
                css={css`
                  margin-left: 4px;
                `}
                component={YearPickerCustom}
                name="birthYear"
                label="Year of birth"
                offsetTopMoved="7px"
              />
            </div>

            <p className="age-note">You must be at least 18 years old to join as a TravelPix photographer.</p>

            <div className="step-control">
              <Button
                css={css`
                    visibility: hidden;
                  `}
                className="btn-control back-btn"
                icon={<ImageIcon className="img-icon" src="/icon/black_left_arrow.svg" />}
                label="Back"
                background="transparent"
                reverse
              />
              <Button
                className="btn-control next-btn"
                type="submit"
                icon={<ImageIcon className="img-icon" src="/icon/right_arrow.svg" />}
                label="Continue"
              />
            </div>
          </form>
        )
      }} />
  );
};

export default DetailStep;
