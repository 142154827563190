import styled from '@emotion/styled';


const ConfirmModalStyle = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 400px;
  width: 345px;
  top: 12%;
  background-color: #fff;
  padding: 0 0 30px 0;
  outline: none;
  border-radius: 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 0px 0px rgba(0, 0, 0, 0.23);

  .logo {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate3d(-50%, -50%, 0);
    max-width: 70px;
    max-height: 70px;
    width: 100%;
    height: 100%;
  }

  .header {
    height: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('/confirm-modal-bg.jpg');
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;

    .title {
      font-size: 32px;
      color: white;
      line-height: 34px;
      font-family: RubikMedium;
      text-align: center;
      text-shadow: 0px 2px 4px rgba(0,0,0,0.5);
      padding: 0 30px;
    }
  }

  .description {
    font-family: RubikRegular;
    color: #a5a7ad;
    font-size: 13px;
    line-height: 18px;
    word-break: break-word;
    position: absolute;
    top: 55%;
    padding: 0 30px;
    text-align: center;
    width: 100%;
  }

  .btn--control {
    padding: 8px 30px;
    height: 46px;
    width: 210px;
    bottom: 5%;
    left: 20%;
    position: absolute;

    span {
      font-family: RubikMedium;
      font-size: 15px;
      line-height: 18px;
      color: #1d2332;
    }
  }

  @media only screen and (max-width: 425px) {
    top: 18%;
  }

  @media only screen and (max-width: 320px) {
    top: 24%;
    width: 90%;

    .btn--control {
      left: 10%;
      width: 80%;
    }
  }
`;

export { ConfirmModalStyle };
