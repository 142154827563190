import styled from '@emotion/styled';

interface RegisterPageStyle {
  currentIndexForm: number;
}

const urlBgForms = ['/r1_bg.png', '/r2_bg.png', '/r3_bg.png'];

const RegisterPageStyle = styled.div<RegisterPageStyle>`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 90px;

  .register-content {
    display: flex;
    justify-content: center;
    padding-bottom: 10.5rem;

    .register-header {
      font-size: 40px;
      line-height: 64px;
      color: #1d2332;
      margin-bottom: 12px;
    }

    .register-container {
      width: 100%;
      max-width: 917px;
      margin: 2.5rem 1.3rem;

      .form-wrapper {
        display: flex;
        flex-direction: row;

        form {
          width: 50%;
          margin-right: 50px;
          padding: 0;
          max-width: 430px;
          .step-form {
            max-width: 400px;
            width: 100%;

            .input-control {
              max-width: 400px;
              &.text-area-control {
                textarea {
                  margin: 20px 0 5px 0;
                  padding: 0px 20px 0px 20px;
                }
              }
            }
          }

          .step-control {
            max-width: 400px;

            .next-btn {
              width: 165px;
              padding: 1em 1.2em 1em 1.75em;
              justify-content: space-between;

               .img-icon {
                margin: 0 0 0 36px;
                width: 15px;
                height: 10px;
              }
            }

            .back-btn {
              .img-icon {
                margin: 0 15px 0 0;
                width: 15px;
                height: 10px;
              }
            }
          }

          @media screen and (max-width: 768px) {
            width: 100%;
            margin-right: 0px;
          }
        }
      }

      .image-header {
        width: 40%;
        background: url(${props => urlBgForms[props.currentIndexForm]});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top;
        max-width: 420px;

        @media screen and (max-width: 768px) {
          background-position: center 0;
        }
        @media screen and (max-width: 540px) {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 540px) {
    align-items: center;
    margin-top: 90px;

    .register-content {
      width: 100%;
      padding-bottom: 0rem;

      .register-container {
        margin-left: 30px;
        margin-right: 30px;

        .register-header {
          display: none;
        }
        .step-form {
          .input-control {
            &.text-area-control {
              textarea {
                margin: 30px 0 0px 0 !important;
              }
            }
          }
          .age-note {
            max-width: 235px !important;
          }
        }
      }
    }
  }
`;
export { RegisterPageStyle };
