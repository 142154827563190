import React, { FunctionComponent } from 'react';
import { css } from '@emotion/core';
import TextArea from '../../../components/Form/TextArea';
import FormField from '../../../components/Form/FormField';
import { Form } from 'react-final-form';
import Button from '../../../components/Form/Button';
import ImageIcon from '../../../components/ImageIcon';

export interface Equipment {
  cameraBrand?: string,
  cameraModel?: string,
  cameraInfo?: string,
}

interface Props {
  initialValues?: Equipment;
  onEquipmentNext: (data: Equipment) => void;
  onEquipmentBack: (data: Equipment) => void;
}

const EquipmentStep: FunctionComponent<Props> = props => {
  const { onEquipmentNext, onEquipmentBack, initialValues } = props;
  var formValues: any = null;

  const onSubmitForm = (values: object) => {
    onEquipmentNext(values);
  }

  const onBack = () => {
    onEquipmentBack(formValues);
  }

  return (

    <Form
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      validate={values => {
        const errors = {};
        if (!values.cameraBrand) {
          errors.cameraBrand = 'This field is required';
        }
        if (!values.cameraModel) {
          errors.cameraModel = 'This field is required';
        }
        if (!values.cameraInfo) {
          errors.cameraInfo = 'This field is required';
        }
        return errors;
      }}
      render={({ handleSubmit, values }) => {
        formValues = values;
        return (
          <form
            onSubmit={handleSubmit}
            css={css`
              width: 100% !important;
              display: flex;
              flex-direction: column;
            `}>

            <FormField
              name="cameraBrand"
              label="Enter your camera brand"
              offsetTopMoved="7px"
            />

            <FormField
              name="cameraModel"
              label="Your camera model"
              offsetTopMoved="7px"
            />

            <FormField
              component={TextArea}
              className="input-control text-area-control"
              name="cameraInfo"
              rows={4}
              label="Any additional information (e.g What kind of lens do you typically use)"
              maxLength={5000}
              offsetTopMoved="7px"
            />

            <div className="step-control">
              <Button
                className="btn-control back-btn"
                icon={<ImageIcon className="img-icon" src="/icon/black_left_arrow.svg" />}
                label="Back"
                background="transparent"
                reverse
                onClick={onBack}
              />
              <Button
                className="btn-control next-btn"
                type="submit"
                icon={<ImageIcon className="img-icon" src="/icon/right_arrow.svg" />}
                label={'Finish'}
              />
            </div>
          </form>
        )
      }} />
  );
};

export default EquipmentStep;
