import React, { FunctionComponent } from 'react';
import { ConfirmModalStyle } from './ConfirmModalStyle';
import Button from '../Form/Button';

export interface ConfirmModalProps {
  className?: string;
  header?: string;
  body?: string;
  buttonText?: string;
  callback?: () => void;
}

const ConfirmModal: FunctionComponent<ConfirmModalProps> = props => {
  const { className, header, body, buttonText, callback } = props;

  return (
    <ConfirmModalStyle className={className}>
      <img className="logo" src="/logo.svg" alt="travelpix-yellow-logo" />
      <div className="header">
        <p className="title">{header}</p>
      </div>
      <p className="description">{body}</p>
      <Button
        label={buttonText}
        className="btn--control"
        onClick={callback}
      />
    </ConfirmModalStyle>
  );

}

export default ConfirmModal;
