import React, { FunctionComponent } from 'react';
import { css } from '@emotion/core';
import { StepStyle, StepLabelStyle } from './StepStyle';

export interface StepProps {
  description?: string;
  isActive?: boolean;
}

interface StepLabelProps {
  index: number;
  label?: string;
  isActive?: boolean;
}

const StepLabel: FunctionComponent<StepLabelProps> = props => {
  const { index, label, isActive } = props;
  return (
    <StepLabelStyle className={isActive ? 'active' : undefined}>
      <div className="circle-container">
        <div className="circle-outer" />
        <div className="circle-inner" />
        <span className="index-label">{index + 1}</span>
      </div>
      <span className="step-label">{label}</span>
    </StepLabelStyle>
  );
};

const Step: FunctionComponent<StepProps> = props => {
  const { description, children, isActive } = props;
  return (
    <StepStyle
      css={css`
      display: ${isActive ? 'flex' : 'none'};
    `}
    >
      <h3 className="description">{description}</h3>
      {children}
    </StepStyle>
  );
};
export { Step, StepLabel };
