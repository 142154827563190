import React, { FunctionComponent, useState } from 'react';
import Layout from '../../layouts/Layout';
import { RegisterPageStyle } from './RegisterPageStyle';
import MultiStepForm from '../../components/MultiStepForm';
import { Helmet } from 'react-helmet';
interface RegisterPageState {
  currentIndex: number;
}

const RegisterPage: FunctionComponent = () => {
  const [state, setState] = useState<RegisterPageState>({
    currentIndex: 0
  }
  );

  const onIndexChange = (index: number) => {
    state.currentIndex = index;
    setState({
      ...state,
    });
  };

  return (
    <Layout isRegisterPage>
      <RegisterPageStyle currentIndexForm={state.currentIndex}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Register</title>
        </Helmet>
        <div className="register-content">
          <div className="register-container">
            <div className="register-header">Complete your application</div>
            <div className="form-wrapper">
              <MultiStepForm onIndexChange={onIndexChange} />

              <div className="image-header " />
            </div>
          </div>
        </div>
      </RegisterPageStyle>
    </Layout>
  );
};

export default RegisterPage;
