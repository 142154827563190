import React, { FunctionComponent, useState } from 'react';
import { ImageUploadStyle } from './ImageUploadStyle';
import ImageIcon from '../ImageIcon';
import Dropzone from 'react-dropzone';
import { css } from '@emotion/core';

interface ImageUploadProps {
  className?: string;
  onFileSelected: (files: [any]) => void;
}

const ImageUpload: FunctionComponent<ImageUploadProps> = props => {
  const { className, onFileSelected } = props;
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {(file.size / (1024 * 1024)).toFixed(2)} MB
    </li>
  ));

  return (
    <ImageUploadStyle className={className}>
      <ImageIcon className="upload-icon" src="/image_upload_icon.svg" alt="Image Upload Icon" />
      <Dropzone
        onDrop={acceptedFiles => {
          acceptedFiles = acceptedFiles.filter(file => file.name.match(/.(jpg|jpeg|png|gif|zip)$/i));
          setAcceptedFiles(acceptedFiles);
          onFileSelected(acceptedFiles);
        }}
        maxSize={50000000}
        accept={'image/*, .zip'}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div className="upload-tips" {...getRootProps()}>
              <span className="main">Select file(s) for upload or drag / drop here</span>
              <span className="sub">(you can upload .ZIP files if there are a lot)</span>
              <span className="sub">Max size is 50MB per file</span>
              <input {...getInputProps()} />
            </div>
            <ul css={css`
              max-width: 300px;
            `}>
              {files}
            </ul>
          </section>

        )}
      </Dropzone>
    </ImageUploadStyle>
  );
};

export default ImageUpload;
