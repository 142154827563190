import styled from '@emotion/styled';

const StepStyle = styled.div`
  display: flex;
  flex-direction: column;
  h3.description {
    font-size: 20px;
    line-height: 24px;
    font-family: RubikMedium;
    font-weight: 400;
    @media screen and (max-width: 540px) {
      text-align: center;
    }
  }
`;

const StepLabelStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  &.active {
    .circle-container {
      .circle-outer {
        background: #fbca00;
        opacity: 1;
      }
      .index-label {
        opacity: 1;
      }
    }
    .step-label {
      opacity: 1;
    }
  }
  .circle-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    .circle-outer {
      width: 100%;
      height: 100%;
      position: absolute;

      border-radius: 50%;
      border: none;
      background: #cdcfd2;
    }
    .circle-inner {
      width: 26px;
      height: 26px;
      position: absolute;
      background: #fff;
      border-radius: 50%;
      border: none;
    }
    .index-label {
      font-family: RubikMedium;
      color: #1d2332;
      font-size: 16px;
      line-height: 19px;
      opacity: 0.3;
      z-index: 1;
    }
  }
  .step-label {
    font-family: RubikMedium;
    font-size: 14px;
    line-height: 17px;
    margin-left: 0.5rem;
    color: #1d2332;
    opacity: 0.3;
    @media screen and (max-width: 540px) {
      display: none;
    }
  }
`;

export { StepStyle, StepLabelStyle };
