import styled from '@emotion/styled';

const PhoneInputStyle = styled.div`
  .react-tel-input {
    .form-control {
      height: 48px;
      width: 100%;
      font-family: RubikMedium;
      font-size: 14px;
    }
  }
`
export { PhoneInputStyle };
