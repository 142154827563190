import React, { FunctionComponent, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { PhoneInputStyle } from './PhoneInputStyle';
import 'react-phone-input-2/lib/style.css';

interface PhoneInputCustomProps {
  className?: string;
  onChangePhone?: (phone: string | undefined) => void;
  input?: any;
  country?: string;
  value?: string;
  enableAreaCodes?: boolean;
}

interface PhoneInputCustomState {
  value: string;
}

const PhoneInputCustom: FunctionComponent<PhoneInputCustomProps> = props => {
  const { className, onChangePhone, input, country, enableAreaCodes } = props;
  const [state, setState] = useState<PhoneInputCustomState>({
    value: input && input.value ? input.value : ""
  });

  const onChange = (phone: string) => {
    setState({
      value: phone
    });
    if (phone) input.onChange(phone);
    if (!onChangePhone) return;
    onChangePhone(phone);
  };

  return (
    <PhoneInputStyle className={className}>
      <PhoneInput
        onChange={onChange}
        country={country}
        value={state.value}
        enableAreaCodes={enableAreaCodes}
      />
    </PhoneInputStyle>
  );
};

export default PhoneInputCustom;
