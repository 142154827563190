import React, { FunctionComponent, useState } from 'react';
import { MonthPicker } from 'react-dropdown-date';
import { MonthPickerStyle } from './MonthPickerStyle';

interface MonthPickerProps {
  className?: string;
  input?: any;
  value?: string;
}

const MonthPickerCustom: FunctionComponent<MonthPickerProps> = props => {
  const { className, input } = props;
  const [state, setState] = useState<MonthPickerProps>({
    value: input && input.value ? input.value : ''
  });

  const onChange = (month: string) => {
    setState({
      value: month
    });
    if (month) input.onChange(month);
  };

  return (
    <MonthPickerStyle className={className}>
      <MonthPicker
        defaultValue={'Month'}
        numeric
        short
        caps
        endYearGiven
        year={new Date().getFullYear() - 18}
        value={state.value}
        onChange={onChange}
        classes={'month-picker'}
      />
    </MonthPickerStyle>
  );
};

export default MonthPickerCustom;
