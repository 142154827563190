import React, { FunctionComponent, useState } from 'react';
import { YearPicker } from 'react-dropdown-date';
import { YearPickerStyle } from './YearPickerStyle';

interface YearPickerProps {
  className?: string;
  input?: any;
  value?: string;
}

const YearPickerCustom: FunctionComponent<YearPickerProps> = props => {
  const { className, input } = props;
  const [state, setState] = useState<YearPickerProps>({
    value: input && input.value ? input.value : ''
  });

  const onChange = (year: string) => {
    setState({
      value: year
    });
    if (year) input.onChange(year);
  };

  return (
    <YearPickerStyle className={className}>
      <YearPicker
        defaultValue={'Year'}
        start={new Date().getFullYear() - 70}
        end={new Date().getFullYear() - 18}
        reverse
        value={state.value}
        onChange={onChange}
        classes={'year-picker'}
      />
    </YearPickerStyle>
  );
};

export default YearPickerCustom;
