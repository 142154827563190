import React, { FunctionComponent, useState } from 'react';
import { DayPicker } from 'react-dropdown-date';
import { DayPickerStyle } from './DayPickerStyle';

interface DayPickerProps {
  className?: string;
  input?: any;
  value?: string;
}

const DayPickerCustom: FunctionComponent<DayPickerProps> = props => {
  const { className, input } = props;
  const [state, setState] = useState<DayPickerProps>({
    value: input && input.value ? input.value : ''
  });

  const onChange = (day: string) => {
    setState({
      value: day
    });
    if (day) input.onChange(day);
  };

  return (
    <DayPickerStyle className={className}>
      <DayPicker
        defaultValue={'Day'}
        year={new Date().getFullYear() - 18}
        month={'0'}
        endYearGiven
        value={state.value}
        onChange={onChange}
        classes={'day-picker'}
      />
    </DayPickerStyle>
  );
};

export default DayPickerCustom;
